/* tslint:disable */
import { Injectable } from "@angular/core";

/**
 * Global configuration for Api services
 */
@Injectable({
  providedIn: "root",
})
export class ApiConfiguration {
  // Staging  
  
  baseUrl2 = 'https://appapi.roca.bigcityvoucher.co.in/v1/';
  // Prod  
//  baseUrl2 = 'https://appapi.redemptionportalrbppl.in/v1/';

  getUserPoints = this.baseUrl2 + 'users/get-user-profile'
  products = this.baseUrl2 + 'product/list-product'
  addToCart = this.baseUrl2 + 'rewards/savecart'
  getCartDetails = this.baseUrl2 + 'rewards/list-cart'
  categories = this.baseUrl2 + 'product/list-product-cats?flag'
  getOTP = this.baseUrl2 + 'users/generate-otp'
  checkout = this.baseUrl2 + 'rewards/orders'
  deleteProductInCart = this.baseUrl2 + 'rewards/deletecartbyprod' 
  redemptionSummary = this.baseUrl2 +'rewards/redemption-summary'
  dashboardDetails = this.baseUrl2 +'rewards/userdashboard'
  pointsSummary = this.baseUrl2 + 'rewards/point-summary'
  clearCart = this.baseUrl2 + 'rewards/clear-cart'
}
export interface ApiConfigurationInterface { 

}

